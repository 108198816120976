import queryString from 'query-string'
import React from 'react'
import { Button, Form } from 'react-bootstrap'
import styled from 'styled-components'
import LoginBanner from '../components/common/LoginBanner'
import { unauthNavTo } from '../components/navigation'
import Spinner from '../components/spinner'
import { useDoResetPasswordMutation } from '../utils/api'

const OuterContainer = styled.div`
@media only screen and (max-width: 768px) {
  min-height: 500px;
}
@media only screen and (min-width: 767px) {
  height: 500px;
}
`

const isNonBlankString = s => typeof s === 'string' && s.trim().length > 0

// markup
const IndexPage = (props) => {
  const { location: { search = '' } = {} } = props
  const { token = null } = queryString.parse(search)
  const [credentials, setCredentials] = React.useState({ password1: '', password2: '' })
  const [errorMessage, setErrorMessage] = React.useState(null)

  // const queryClient = useQueryClient()
  const doResetPasswordMutation = useDoResetPasswordMutation()

  // navigate on success
  React.useEffect(() => {
    if (doResetPasswordMutation.isSuccess) {
      setErrorMessage(null)
      unauthNavTo.resetPasswordComplete(credentials)
    }
  }, [doResetPasswordMutation.isSuccess, credentials])

  // show message on error
  React.useEffect(() => {
    if (doResetPasswordMutation.isError) {
      setErrorMessage(doResetPasswordMutation.error.message)
    }
  }, [doResetPasswordMutation.isError, doResetPasswordMutation.error])

  const handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()
    if (!isNonBlankString(credentials.password1)) return setErrorMessage('Passwords cannot be blank.')
    if (!isNonBlankString(credentials.password2)) return setErrorMessage('Passwords cannot be blank.')
    if (credentials.password1 !== credentials.password2) return setErrorMessage('Passwords do not match.')
    setErrorMessage(null)

    doResetPasswordMutation.mutate({ token, password: credentials.password1 })
  }

  return (
    <main className="main flex-fill" id="top">
      <div className="container-fluid">
        <div className="row min-vh-100 flex-center no-gutters">
          <div className="col-lg-8 col-xxl-5 py-3">
            <OuterContainer className="card overflow-hidden z-index-1">
              <div className="card-body p-0">
                <div className="row no-gutters h-100">
                  <LoginBanner showRegisterNow={false} />
                  <div className="col-md-7 d-flex flex-center">
                    <div className="px-4 px-md-5 d-flex flex-column flex-grow-1 my-5" style={{ minHeight: '300px' }}>
                      <h3 className="mb-3 font-weight-bold">Reset your password</h3>

                      <p>Please enter your new password.</p>

                      {/* form */}
                      <Form noValidate validated={false} onSubmit={handleSubmit} className="d-flex flex-column flex-grow-1 justify-content-between">

                        <div>
                          <Form.Group controlId="validationCustom01">
                            <Form.Label>New password</Form.Label>
                            <Form.Control
                              type="password"
                              // placeholder="Email address"
                              autoComplete="password1"
                              autoFocus={true}
                              value={credentials.password1}
                              onChange={e => setCredentials(ps => ({ ...ps, password1: e.target.value }))}
                              disabled={doResetPasswordMutation.isLoading}
                              isInvalid={errorMessage !== null}
                            />
                          </Form.Group>
                          <Form.Group controlId="validationCustom02">
                            <Form.Label>Re-enter new password</Form.Label>
                            <Form.Control
                              type="password"
                              // placeholder="Password"
                              autoComplete="current-password"
                              value={credentials.password2}
                              onChange={e => setCredentials(ps => ({ ...ps, password2: e.target.value }))}
                              disabled={doResetPasswordMutation.isLoading}
                              isInvalid={errorMessage !== null}
                            />
                            <Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback>
                          </Form.Group>

                        </div>

                        <Form.Group className="mb-0">
                          <Button
                            className="btn-block"
                            type="submit"
                            // disabled={doResetPasswordMutation.isLoading || credentials.password1.length === 0 || credentials.password2.length === 0}
                            // onClick={(e) => doResetPassword(e)}
                          >
                            { doResetPasswordMutation.isLoading ? <Spinner /> : <span>Reset Password</span> }
                            </Button>
                        </Form.Group>

                      </Form>

                    </div>
                  </div>
                </div>
              </div>
            </OuterContainer>

            { process.env.NODE_ENV !== 'production' && <pre>credentials={JSON.stringify(credentials, null, 2)}</pre>}
            { process.env.NODE_ENV !== 'production' && <pre>token={JSON.stringify(token, null, 2)}</pre>}
          </div>
        </div>
      </div>
    </main>
  )
}

export default IndexPage
